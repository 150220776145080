<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small" @submit.native.prevent>
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" @keyup.enter.native="query"/>
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ scope.row.id }}
				  </template>
				</el-table-column>
				<el-table-column label="封面" min-width="110" align="center">
				  <template slot-scope="scope">
					  <img :src="scope.row.url" style="width: 35px;height: 35px;" @click="viewImage(scope.row,'url')" v-if="scope.row.url">
					  <img src="@/assets/images/empty.png" style="width: 35px;height: 35px;" v-else>
				  </template>
				</el-table-column>
				<el-table-column label="活动名称" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.title }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="活动时间" min-width="145" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.stime }} - {{ scope.row.etime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="报名人数" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.nums }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="推荐" min-width="60" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.is_rec === 1" size="mini" type="success">是</el-tag>
					<el-tag v-if="scope.row.is_rec === 2" size="mini" type="danger">否</el-tag>
				  </template>
				</el-table-column>
				<el-table-column label="状态" min-width="60" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.status === 1" size="mini" type="success">正常</el-tag>
					<el-tag v-if="scope.row.status === 2" size="mini" type="danger">停用</el-tag>
				  </template>
				</el-table-column>
				<el-table-column label="结束状态" min-width="60" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.is_finish === 1" size="mini" type="success">进行中</el-tag>
					<el-tag v-if="scope.row.is_finish === 2" size="mini" type="danger">已结束</el-tag>
					<el-tag v-if="scope.row.is_finish === 3" size="mini" type="warning">准备中</el-tag>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="160">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.utime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="查看" placement="top">
					    <el-button type="warning" icon="el-icon-view" size="mini" circle @click="handleView(scope.row)"></el-button>		
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="编辑" placement="top">
					  <el-button type="success" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'activity',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
			}
		},
		mounted() {
			
		},
		methods:{
			handleAdd(){
				this.$router.push({
				  path:"/activity_edit",
				  query: {}
				})
			},
			handleEdit(row) {
				this.$router.push({
				  path:"/activity_edit",
				  query: {
					  article:JSON.parse(JSON.stringify(row))
				  }
				})
			},
			handleView(row){
				this.$router.push({
				  path:"/activity_list",
				  query: {
					  id:row.id
				  }
				})
			}
		},
	}
</script>

<style>
</style>